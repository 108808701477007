import {Component, Input, OnChanges} from '@angular/core';
import {CommonModule} from '@angular/common';
import {escapeForRegExp} from '../../../utils/string.utils';

@Component({
    selector: 'app-highlight-term',
    standalone: true,
    template: `<span *ngFor="let part of parts; let i = index" [class.highlight-text]="i % 2">{{part}}</span>`,
    imports: [
        CommonModule,
    ],
})
export class HighlightTermComponent implements OnChanges {
    @Input() text: string | number;
    @Input() term: string;
    parts: string[];

    ngOnChanges() {
        if (typeof this.text === 'number') this.text = this.text.toString();
        this.parts = this.term ? this.text?.split(new RegExp(`(${escapeForRegExp(this.term)})`, 'gi')) : [this.text];
    }
}
